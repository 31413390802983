import React from 'react';
import moment from 'moment';
import useConsumedCreditsStart from '../../hooks/useConsumedCreditsStart';
import {Alert} from '@material-ui/lab';

const HistoricalDataAlert = ({publisherStats}) => {
  const consumedCreditsStart = useConsumedCreditsStart();
  const lastStatsDate = publisherStats?.length > 0 ? [...publisherStats].pop()?.date : null;
  const historicalDataLoaded =
    moment(lastStatsDate).isBefore(moment(consumedCreditsStart?.startDate).subtract(1, 'days')) ||
    !consumedCreditsStart?.startDate;

  if (consumedCreditsStart && historicalDataLoaded && publisherStats.length > 0) {
    return (
      <Alert severity="warning">
        Reporting displays historical and live data from your GAM account before Setupad
        implementation. It will indicate the launch date when Setupad codes are on the website.
      </Alert>
    );
  } else {
    return null;
  }
};

export default HistoricalDataAlert;
